<template>
<div>

    <div class="template-page homepage grey-bg yellow-step research-section">
        <basic-page :pagedata="{title: this.pageData.title}" :bannerimg="this.bannerimg">

            <div class="home-header step-big">
                <div class="home-1" v-if="this.pageData.body">
                    <div class="title">
                        {{ this.pageData.body[0].summary }}
                    </div>
                    <div class="content-1">
                        <p v-html="this.pageData.body[0].processed"></p>
                    </div>
                    <div class="content-2">
                        <p v-html="this.pageData.body[1].processed"></p>
                        <router-link class="cta-link nospan blue" to="/why-data-science-for-the-natural-sciences"> 
                            <span>Learn More</span>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="container container-expertise">
                <div v-for="(r, index) in this.ruData" :key="'research'+index" class="item-expertise">
                    <div class="title-container">
                        <img src="../assets/placeholder-expertise.svg" alt="">
                        <h3>{{ r.attributes.title }}</h3>
                    </div>
                    <div class="description">
                        <p v-html="r.attributes.field_ru_description.summary"></p>
                        <div>
                            <router-link :to="researchUnitLink(r)" class="cta-link">
                            <span>Learn More</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </basic-page>
    </div>

    <div class="template-page blue-bg">
        <div class="container">
            <div class="row" v-if="this.firstLink.attributes && this.secondLink.attributes">
                <div class="column-tile angle light-angle">
                    <div class="tile-title">
                        <p>Education</p>
                        <!--<h2>{{ this.firstLink.attributes.title }}</h2>-->
                        <h2>Postgraduate opportunities</h2>
                    </div>
                    <div class="description">
                        <p v-html="this.firstLink.attributes.body[0].summary"></p>
                        <div class="inner-section-4">
                            <router-link :to="this.firstLink.attributes.path.alias" class="cta-link ">
                                <span>View More</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column-tile angle light-angle">
                    <div class="tile-title">
                        <p>Knowledge Transfer</p>
                        <h2>{{ this.secondLink.attributes.title }}</h2>
                    </div>
                    <div class="description">
                        <p v-html="this.secondLink.attributes.body[0].summary"></p>
                        <div class="inner-section-4">
                            <router-link :to="this.secondLink.attributes.path.alias" class="cta-link ">
                                <span>View More</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="template-page news-section opportunities-section" v-if="this.opportunitiesNews.length > 0">
        <div class="container">
            <h2 class="title-section">Latest Opportunities</h2>    
            <div class="inner-section-2">
                <router-link to="/opportunities" class="cta-link nospan">
                    <span>View More</span>
                </router-link>

            </div>

            <div v-for="(n, index) in this.opportunitiesNews" :key="'news'+index" >
                <single-news :data="n"></single-news>
            </div>
        </div>
    </div>

    <div class="template-page news-section" v-if="this.newsData.length > 0">
        <div class="container">
            <h2 class="title-section">Latest News</h2>    
            <div class="inner-section-2">
                <router-link to="/news" class="cta-link nospan">
                    <span>View More</span>
                </router-link>
            </div>

            <div v-for="(n, index) in this.newsData" :key="'news'+index" >
                <single-news :data="n"></single-news>
            </div>
        </div>
    </div>

    <div class="template-page blue-bg seminars-section" v-if="this.seminarData.length > 0">
        <div class="container">
            <h2 class="title-section">Latest Seminars</h2>    
            <div class="inner-section-3">
                <router-link to="/seminars-and-journal-clubs" class="cta-link nospan">
                    <span>View More</span>
                </router-link>
            </div>

            <router-link :to="seminarLink(seminar)" v-for="(seminar, index) in this.seminarData" :key="'upcoming-seminar'+index">
                <upcoming-seminar :data="seminar"></upcoming-seminar>
            </router-link>
        </div>
    </div>

    <div class="template-page events-section">
        <div class="container container-events">
            <h2 class="title-section">Latest Events</h2>    
            <div class="inner-section-2">
                <router-link to="/events-for-the-public" class="cta-link nospan">
                    <span>View More</span>
                </router-link>
            </div>

            <div class="events">                                                                                              
                <router-link :to="eventLink(event)" v-for="(event, index) in this.evData" :key="'event'+index">
                    <event-tile :eventdata="event"></event-tile>
                </router-link>
            </div>
        </div>
    </div>

    <div class="combine-back">
        <div class="image-banner"></div>
        <div class="combine-banner">
            <div class="field" v-if="this.pageData.body && this.pageData">
                <div class="title">
                    {{ this.pageData.body[2].summary }}
                </div>
                <div class="text">
                    <p v-html="this.pageData.body[2].processed"></p>
                </div>
                <router-link class="cta-link nospan blue" to="/why-data-science-for-the-natural-sciences"> 
                    <span>Learn More</span>
                </router-link>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import BasicPage from './BasicPage.vue'
import SingleNews from '../components/SingleNews.vue'
import UpcomingSeminar from '../components/UpcomingSeminar.vue'
import EventTile from '../components/EventTile.vue'
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
import { detailsLink } from '../libs/utils'

export default {
  components: { BasicPage, SingleNews, UpcomingSeminar, EventTile },
    name: 'home',
    data: () => {
        return {
            currentPageId: "e6d11462-4f46-4927-b0fd-480607f03f4f",
            pageData: {},
            newsData: {},
            opportunitiesNews: {},
            seminarData: {},
            evData: {},
            ruData: {},
            bannerimg: null,
            firstLinkId: "a120c93f-3150-4793-9bda-6b28e620368e",
            secondLinkId: "fd76e9c2-30bb-4bb8-9b9d-d15a4f48a40b",
            firstLink: {},
            secondLink: {}
        }
    },
    methods: {
        seminarLink(e) {
            return detailsLink('seminar', e)
        },
        eventLink(e) {
            return detailsLink('event', e)
        },
        researchUnitLink(ru) {
            if(ru)
                return detailsLink('research-unit', ru)
            else return '#'
        }
    },
    computed: {
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { 
            include: ['field_banner_top']
        }).then(res => {
            //console.log(res)
            this.pageData = res[0].attributes
            if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
        })

        fetchNodes('page', {})
        .then(res => {
            this.firstLink = res.find(p => p.id == this.firstLinkId)
            this.secondLink = res.find(p => p.id == this.secondLinkId)
            //console.log(this.firstLink, this.secondLink)
        })

        fetchNodes('news', {
            include: ['field_news_sector', 'field_news_image', 'field_news_attachment']
        }).then(res => {
            this.opportunitiesNews = res.filter(n => n.attributes.field_is_opportunity_news == true)
                .filter((o) => {
                    let today = new Date();
                    let deadlineDate = new Date(o.attributes.field_opportunity_deadline);
                    deadlineDate = deadlineDate.setDate(deadlineDate.getDate() + 1)

                    if( today <= deadlineDate ){
                        return true
                    } 

                })
                .sort((a,b) => { return a.attributes.field_opportunity_deadline > b.attributes.field_opportunity_deadline ? -1 : 1 }).slice(0, 2)

            this.newsData = res.filter(n => n.attributes.promote && n.attributes.field_is_opportunity_news == false)
                .sort((a,b) => { return a.attributes.field_news_data > b.attributes.field_news_data ? -1 : 1 })
        })

        fetchNodes('seminar', {include:['field_seminar_image', 'field_seminar_internal_speaker', 'field_ref_seminar_type', 'field_ref_seminar_cat']
        }).then(res => {
            this.seminarData = res//.filter(s => { return Date.parse(s.attributes.field_seminar_end_date) > Date.now() })
                .sort((a, b) => { return Date.parse(a.attributes.field_seminar_end_date) > Date.parse(b.attributes.field_seminar_end_date) ? -1 : 1 })
                .slice(0, 2)
        })

        fetchNodes('event', {
            include: ['field_event_image']
        }).then(res => {
            //console.log(res)
            res = res//.filter(s => { return Date.parse(s.attributes.field_event_start_date) > Date.now() })
                .sort((a, b) => { return Date.parse(a.attributes.field_event_start_date) > Date.parse(b.attributes.field_event_start_date) ? -1 : 1 })
                .slice(0, 3)
            this.evData = res
        })

        fetchNodes('research_unit', {})
        .then(res => { this.ruData = res })

    }


}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
.container {
    position: relative;
    a {
        text-decoration: none;
    }
}

.homepage {
   ::v-deep .main-content {
        background: url("../assets/filigrana-pattern-research.png") no-repeat left bottom, url("../assets/pattern-grey.png");
    }
}

.container-expertise {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0 -40px;
}

.container-events  {
    position: relative;
    z-index: 1;
    
    .events {
        position: relative;
        z-index: 1;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        
        > a {
            text-decoration: none;
            display: block;
            width: 33.33%;
            margin: 0;
            margin-bottom: 40px;
        }
    }

    @media (max-width:992px){
        .events > a{
            width: 100%;
            .event-wrapper {
                padding: 0px;
                border-right: 0px solid #F0F0F0;
            }
        }
    }

    @media (max-width:576px){
        .yellow-angle::after {
            top: 0px;
            right: 0px;
        }
        .event-wrapper .content .info,
        .event-wrapper .content .date {
            width: auto;
        }
            .event-wrapper .content .info {
                padding-right: 45px;
        }
    }
}

//.blue-bg  .container,
.news-section .container,
.events-section .container,
.seminars-section .container {
    padding-bottom: 30px;
    padding-top: 15px;
}

.inner-section-4,
.inner-section-3,
.inner-section-2 {
    position: absolute;
    right: 40px;
    top: 55px;

    @media (max-width: 768px) {
        position: relative;
        right: 0;
        top: -40px;
    }
    
    a {
        text-decoration: none;
        width: 160px;
        height: 3.5em;
        background-color: transparent;

        &:hover span {
            color: $secondaryColor;
        }
        span {
            color: #fff;
        }
    }
}

.inner-section-4 {
    position: relative;
    top: unset;
    bottom: 0;
    left: 0;
}

.inner-section-2 {
    a {
        span {
            color: $secondaryColor;
        }
    }
}

.home-header {
    background: $mainColor;
    display: flex; display: -webkit-flex;
    top: -65px;
    flex-direction: row;
    position: relative;

    @media (max-width: 768px) {
      top: 0;
    }

    .home-1 {
        position: relative;
        width: 100%;
        display: flex; display: -webkit-flex;
        flex-direction: row;
        justify-content: space-between;
        
        .title {
            width: 25%;
            margin-left: 50px;
            font-size: 2em;
            padding: 20px 0;

            &::before {
                content: '';
                background: #fff;
                width: 45px;
                height: 3px;
                display: block;
                position: absolute;
                top: 45px;
                left: -10px;
            }
        }

        .content-1, .content-2 {
            width: 35%;
            padding: 0 20px;
        }
    }
}

.row {
    padding: 40px;
    display: flex; display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;

    .column-tile {
        position: relative;
        width: 49%;
        min-height: 500px;
        border: 1px rgba(255, 255, 255, 0.5) solid;

        .tile-title {
            padding: 40px;
            height: 35%;
            border-bottom: 1px rgba(255, 255, 255, 0.5) solid;

            p {
                color: #fff;
            }

            h2 { 
                padding: 0;
                font-size: 2.5em;
                margin-top: 7px;
                line-height: 2.8rem;
            }
        }

        .description {
            padding: 40px;
            height: 65%;

            p {
                color: rgba(255, 255, 255, 0.7);
                font-weight: 300;
            }
        }
    }
}

.combine-back {
    position: relative;
    width: 100%;
    padding-left: 20%;
    top: -50px;


    .image-banner {
        background-image: url(../assets/banner.jpg);
        background-size: cover;
        width: 100%;
        height: 500px;
        background-repeat: no-repeat;
        background-position: center right;
        &:after {
            content: "";
            opacity: 0.5;
            background-image: url("../assets/pattern-overlay-header.png");
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            left: 20%;
            display: block;
            //position: absolute;
        }
    }

    .combine-banner {
        background: $mainColor;
        min-width: 50%;
        max-width: 70%;
        height: 80%;
        position: absolute;
        top: 40px;
        left: 0;
        padding-left: 20%;

        .field {
            display: flex; display: -webkit-flex;
            flex-direction: column;
            padding: 40px;

            .title {
                margin-left: 60px;
                font-size: 2em;
                color: $secondaryColor;
                position: relative;

                &::before {
                    content: '';
                    background: #fff;
                    width: 40px;
                    height: 3px;
                    display: block;
                    position: absolute;
                    top: 25px;
                    left: -60px;
                }
            }
            .text {
                margin: 20px 0;
            }
        }
    }
}

@media (max-width: 1140px) {
    .home-header {
        .home-1 {
            flex-direction: column;
            &> div {
                margin-bottom: 20px;
            }
                .title {
                    width: 100%;
                }
                .content-1,
                .content-2 {
                    width: 90%;
                    margin-left: 40px;
                }
            }
    }

    .row {
        flex-direction: column;

        .column-tile {
            width: 100%;
            margin: 20px 0;
        }
    }

    .inner-section-4 {
        position: relative;
        left: 0;
    }
}

@media (max-width: 576px) {
    .row, .container-expertise {
        width: 100%;
        padding: 0;
    }

    .combine-back .combine-banner .field {
        .title {
                margin-left: 0;
                position: relative;
                &::before {
                    width: 40px;
                    bottom: -10px;
                    left: 0;
                    top: unset;
                }
            }
    }
}

@media (max-width: 768px) {
    .home-header {
        .home-1 {
            .title {
                margin-left: 0;
                position: relative;
                &::before {
                    width: 40px;
                    bottom: -10px;
                    left: 0;
                    top: unset;
                }
            }
            .content-1, .content-2 {
                margin: 0;
            }
        }
    }
}

@media (max-width: 992px) {
    .container-expertise {
        margin: 0 ;
    }

    .home-header .home-1 .content-1, .home-header .home-1 .content-2 {
        width: auto;
    }

    .combine-back {
        display: flex; display: -webkit-flex;
        flex-direction: column;
        padding: 0;
        justify-content: flex-start;

        .image-banner {
            &::after {
                left: 0;
            }
        }

        .combine-banner {
            position: relative;
            top: -40px;
            padding-left: 10px;
            width: 100vw;
            min-width: unset;
            max-width: unset;
            height: fit-content;
        }
    }
}

.seminars-section {
    background-image: url(../assets/filigrana-squares-header.png);
    background-position: bottom right;
    background-repeat: no-repeat, repeat;
}

.news-section, 
.events-section {
    background-image: url(../assets/filigrana-white-90deg.png);
    background-position: bottom left;
    background-repeat: no-repeat;
}

.opportunities-section {
    background-color:#F0F0F0 ;
    background-image: url(../assets/filigrana-white-90deg.png);
    background-position: bottom left;
    background-repeat: no-repeat;

    h2.title-section {
        border-bottom: 2px solid #e5e5e5;
    }

    .news-wrapper {
        background-color: #fff;
        padding: 16px 16px;
        margin-bottom: 26px;
    }

    ::v-deep .date.deadline.expired {
        background:transparent;
    }
}

.events-section {
    padding-bottom: 120px;
}

.column-tile {
    background-image: url(../assets/tile-pattern.png);
    background-position: bottom right;
    background-repeat: no-repeat;
}


@media (min-width:1024px){
    .opportunities-section {
        .news-wrapper {
            padding: 20px 5px 20px 26px;
        }

        ::v-deep .news-wrapper .image-tile {
            margin:0;
        }

    }
}

</style>