<template>
  <div class="seminar-wrapper">

    <div class="info-column">
      <div class="date">
          <p class="day">{{ seminarStartDate.getDate() }}</p>
          <p class="month">{{ month }}</p>
          <p class="year">{{ seminarStartDate.getFullYear() }}</p>
      </div>
      <div class="details">
          <span class="tag">{{ this.data.field_ref_seminar_type.attributes.name }}</span>
          <p class="hours">{{ this.sameDay ? (this.startHour + ' - ' + this.endHour) : this.startHour }}</p>
          <p class="room">{{ this.data.attributes.field_seminar_location }}</p>
      </div>
    </div>

    <div class="external image">
      <span class="image-tag" v-if="this.data.field_ref_seminar_cat">
        {{this.data.field_ref_seminar_cat.attributes.name}}
      </span>
      <a>
        <img :src="seminarImage">
      </a>
    </div>

    <div class="content">
      <p class="title">{{ this.data.attributes.title }}</p>
      <p class="description" v-if="this.data.attributes.field_seminar_description" v-html="this.data.attributes.field_seminar_description.processed.substring(0, 250)+'...'"></p>
      <span class="speaker-tag" v-if="allSpeakers.length > 0">speaker(s)</span>
      <div class="speaker" >
        <div v-for="(speaker, index) in allSpeakers" :key="index" >
          <router-link v-if="speaker.type=='internal'" :to="personLink(speaker)">
            <p class="speaker-name">{{speaker.name}}<span v-if="allSpeakers.length>1">,</span></p>
          </router-link>
          <p v-else class="speaker-name">{{speaker.name}}<span v-if="allSpeakers.length>1">,</span></p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { convertMonth, detailsLink  } from '../libs/utils'
export default {
  name: 'upcoming-seminar',
  props: {
    data: Object
  },
  methods: {
    personLink(p) {
      //console.log(p)
      var sp = {
        attributes: {
          field_first_name: p.name.split(' ')[0],
          field_last_name: p.name.split(' ')[1],
          drupal_internal__nid: p.nid
        }
      }
      return detailsLink('person', sp)
    },
    seminarLink(e) {
      return detailsLink('seminar', e)
    }
  },
  computed: {
    startHour() {
      let d = new Date(Date.parse(this.data.attributes.field_seminar_start_date))
      let h = d.getHours()
      let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
      if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
      else h = h.toString() + ':' + m + ' am'
      return h
    },
    endHour() {
      let d = new Date(Date.parse(this.data.attributes.field_seminar_end_date))
      let h = d.getHours()
      let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
      if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
      else h = h.toString() + ':' + m + ' am'
      return h
    },
    sameDay() {
      return (this.seminarStartDate.getDate() == this.seminarEndDate.getDate()
        && this.seminarStartDate.getMonth() == this.seminarEndDate.getMonth()
        && this.seminarStartDate.getFullYear() == this.seminarEndDate.getFullYear())
    },
    allSpeakers() {
      var tot = []

      this.data.attributes.field_seminar_external_speaker.forEach(esp => {
        tot.push({ type: 'external', name: esp })
      });
      this.data.field_seminar_internal_speaker.forEach(isp => {
        tot.push({ type: 'internal', name: isp.attributes.field_first_name + ' ' + isp.attributes.field_last_name, nid: isp.attributes.drupal_internal__nid })
      })
      //console.log(tot)

      return tot.sort((a, b) =>  {
        return a.name.split(' ')[1] > b.name.split(' ')[1] ? 1 : -1
      })
    },
    seminarStartDate() {
      return new Date(Date.parse(this.data.attributes.field_seminar_start_date))
    },
    seminarEndDate() {
      return new Date(Date.parse(this.data.attributes.field_seminar_end_date))
    },
    month() {
      return convertMonth(this.seminarStartDate.getMonth())
    },
    seminarImage() {
      if(this.data.field_seminar_image)
        return (process.env.VUE_APP_ENDPOINT + this.data.field_seminar_image.attributes.uri.url)
      else
        return '/placeholder-people.svg'
    }
  },
  mounted() {
    //console.log(this.data)
  }
}
</script>

<style lang="scss"  >
@import "../styles/colors.scss";


.seminar-wrapper .image-tag {
  position: absolute;
  margin-right: 10px;
  font-size: 0.75em;
  padding: 0.75em 1.5em;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  background: #fddb40;
  color: #121e33;
  text-transform: uppercase;
  font-weight: 700;
  top: 0;
  left: 10%;
}

.seminar-wrapper {
  margin:  0 0 3rem 0 ;
  cursor: pointer;
  display: flex; display: -webkit-flex;
  flex-direction: row;
  position: relative;
  * { color: #fff; }
  
  &::after {
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    content: "";
    top: 20px;
    right: 20px;
    border-style: solid;
    border-color: #FDDB40;
    border-width: 5px 5px 0px 0px;
  }
  transition: 0.3s ease;
  border: 1px rgba(255, 255, 255, 0.3) solid;

  &:hover {
    border: $mainColor 1px solid;
    transition: 0.3s ease;
 
    .date {
      background: $mainColor;
      transition: 0.3s ease;
      .day { color: #fff;transition: 0.3s ease; }
      .month { color: $secondaryColor;transition: 0.3s ease; }
      .year { color: $secondaryColor; transition: 0.3s ease;}
    }
    .content {
      .title {

        color: $mainColor;
        text-decoration: none;
        transition: 0.3s ease;
      }
    }
  }
}

.info-column {
  width: 20%;
  display: flex; display: -webkit-flex;
  flex-direction: column;
}

.seminar-wrapper .date {
  padding: 1em 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  transition: 0.3s ease;
  &:after {
    background-image: url("../assets/icon-calendar-white.svg");
    content: "";
    width: 20px;
    height: 20px;
    top: 0;
    right:  0;
    margin: 30px;
    position: absolute;
  }
  .day { 
    font-size: 2.5rem;
    @media (min-width: 992px) {
      font-size: 4.1875rem;
      font-weight: 300;
    }
    line-height: 1em;
    color: $mainColor;
  }
  .month {
    font-size: 1.75rem;
    margin: 7px 0rem;
  }
  .year { 
    font-size: 1rem;
    color: #fff;
    opacity: 0.5;
  }
}

.details {
  padding: 1em 20px;
  .hours { margin-top: 1rem; }
  .hour { font-size: 1.25rem; }

  span {
    color: #A5A5A5;
    font-size: 0.875rem;
  }
  .room {
    color: $mainColor;
    font-size: 1rem;
  }

  .tag {
    font-size: .8em;
    font-weight: 700;
    padding: .3em 1em;
    padding-left: 2.2rem;
    display: block;
    margin-bottom: .5em;
    text-transform: uppercase;
    color: #999;
    position: relative;

    &:before {
      content: url(../assets/circle.svg);
      width: 12px;
      height: 12px;
      display: inline-block;
      top: .4em;
      left: .5em;
      position: absolute;
      vertical-align: middle;
    }
  }
}

.image {
    width: 35%;
    overflow: hidden;
    position: relative;

    &::before{
      content: "";
      opacity: 0.5;
      background-image: url("../assets/pattern-overlay-person.png");
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      right: 0px;
      display: block;
      position: absolute;
    } 

    a {
        display: block;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-position: center center;
            object-fit: cover;
        }
    }
}

.seminar-wrapper .content {
    width: 45%;
    padding: 2em 3rem 2em 3rem;

    .title {
      font-size: 1.625rem;
      margin-bottom: 20px;
      color: #fff;
    }

    .description {
      color: #fff;
      font-size: 1em;
    }

    .cta-link {
      margin-bottom: 40px;
      text-decoration: none;
    }

    a {
      background: rgba(255, 255, 255, 0.3);   
      max-width: 200px;     
    }

    .speaker {
      display: flex; display: -webkit-flex;
      flex-direction: row;
      flex-wrap: wrap;
      >div:last-child{
        span{
          display: none;
        }
      }
    }
    .speaker-tag {
      text-transform: uppercase;
      color: #999;
      font-size: 0.875rem;
      display: block;
      font-weight: 700;
    }
    .speaker-name {
      margin-right: 7px;
      font-size: 1.15rem;
      span {
        color: #fff;
         font-size: 1.15rem;
      }
    }
}

@media (max-width: 992px) {
  
  .seminar-wrapper .image-tag {
    left: auto;
    right: 30px;
  }
  
  .seminar-wrapper {
    flex-direction: column;
    &> div {
      width: 100%;
    }

    .info-column {
      flex-direction: row;

      .date {
        border: none;
        height: auto;
        width: 50%;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }
      .speaker {
        height: auto;
        width: 50%;
      }
    }
    .content { width: 100%; }

    .external {
      padding: 0;
      
      img {
        max-height: 300px;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 768px){
  .seminar-wrapper .content {
    padding: 2em 2rem 2em 2rem;
  }

  
}
@media (max-width: 576px){ 
.seminar-wrapper {

  .info-column {
    display: block;
    .date {
      border: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      width: 100%;
      border: 0;
      &:after {
        display: none;
      }
    }
  }

  

  .content{ padding: 1em 20px; }
  }
}
</style>