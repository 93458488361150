<template>
    <div class="event-wrapper angle yellow-angle">
        <div class="content-event">
            <div class="date">
                <div class="day">{{ eventStartDate.getDate() }}</div>
                <div class="month-year">{{ monthStart  + ' ' + eventStartDate.getFullYear() }}</div>
            </div>
            <div class="info">
                <div class="hours">{{ this.sameDay ? (this.startHour + ' - ' + this.endHour) : this.startHour }}</div>
                <div class="location-1">{{ this.eventdata.attributes.field_event_location }}</div>
            </div>
        </div>
        <div class="image">
            <img :src="photoSource(this.eventdata)" alt="">
        </div>
        <div class="title">
            {{ this.eventdata.attributes.title }}
        </div>
    </div>
</template>

<script>
import { convertMonthShort } from '../libs/utils'

export default {
    name: 'event-tile',
    props: {
        eventdata: {}
    },
    computed: {
        eventStartDate() {
            return new Date(Date.parse(this.eventdata.attributes.field_event_start_date))
        },
        eventEndDate() {
            return new Date(Date.parse(this.eventdata.attributes.field_event_end_date))
        },
        monthStart() {
            return convertMonthShort(this.eventStartDate.getMonth())
        },
        monthEnd() {
            return convertMonthShort(this.eventEndDate.getMonth())
        },
        startHour() {
            let d = new Date(Date.parse(this.eventdata.attributes.field_event_start_date))
            let h = d.getHours()
            let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
            if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
            else h = h.toString() + ':' + m + ' am'
            return h
        },
        endHour() {
            let d = new Date(Date.parse(this.eventdata.attributes.field_event_end_date))
            let h = d.getHours()
            let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
            if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
            else h = h.toString() + ':' + m + ' am'
            return h
        },
        sameDay() {
            return (this.eventStartDate.getDate() == this.eventEndDate.getDate()
                && this.eventStartDate.getMonth() == this.eventEndDate.getMonth()
                && this.eventStartDate.getFullYear() == this.eventEndDate.getFullYear())
        }
    },
    methods: {
        photoSource(ev) {
            if (ev) {
                if (ev.field_event_image) {
                    return process.env.VUE_APP_ENDPOINT + ev.field_event_image.attributes.uri.url
                } else {
                    return '/events-placeholder.jpg'
                }
            } else {
                return '/events-placeholder.jpg'
            }
        },
        mounted() {
            //console.log(this.eventdata)
        }
    }
}
</script>

<style lang="scss" >
@import "../styles/colors.scss";

.event-wrapper.angle:after {
    right: 30px;
}

a:nth-child(3n-3) {
    .event-wrapper {
        border-right: 0px;
    }
}

.event-wrapper {
    position: relative;
    padding: 10px 30px;
    border-right: 1px solid #F0F0F0;

    &:hover {
        transition: all 300ms ease;
        background: #fbfbfb;
        .title { color: $mainColor; }
        .image { opacity: .8; transition: all 300ms ease; }
    }

    .content-event {
        display: flex; display: -webkit-flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 20px;
        padding: 0;
        width: 100%;
        min-height: 70px;
        .date {
            width: 30%;
            display: flex; display: -webkit-flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: -5px;

            .day {
                font-size: 3em;
                line-height: 1em;
                color: $mainColor;
            }
        }

        .info {
            width: 70%;
            display: flex; display: -webkit-flex;
            flex-direction: column;
            //justify-content: flex-end;
            .hours {
                color: $secondaryColor;
                font-weight: 700;
            }
            .location-1 {
                font-size: 1.2em;
            }
            .location-2 {
                font-size: 1em;
            }
        }
    }

    .image {
        position: relative; 
        width: 100%;
        height: 180px;

        &::after{
            content: "";
            opacity: .5;
            background-image: url("../assets/pattern-overlay-person.png");
            width: 100%;
            height: calc(100% - 4px);
            z-index: 0;
            top: 0;
            right: 0px;
            display: block;
            position: absolute;
        }

        img {
            height: 180px;
            object-fit: cover;
            width: 100%;
        }
    }

    .title {
        padding: 0;
        font-size: 1.5em;
        color: $secondaryColor;
        margin-top: 10px;
    }

    .month-year {
        font-size: 0.9375rem;
        color: #112342;
        text-transform: uppercase;
        font-weight: 500;
    }
}
.past {
    .event-wrapper .title {
        font-size: 1.25rem;
    }
    .content-event .info .hours {
        font-weight: unset;   
    }
}

@media (max-width:1500px){
    .events > a{
        width: 50%;
    }
    .event-wrapper .content .info {
        //justify-content: flex-end;
    }

}

@media (max-width:992px){
    .event-wrapper.angle:after {
        right: 0px;
    }
    .event-wrapper .title {
        font-size: 1.75rem;
    }
    .event-wrapper .content-event .date .day {
        font-size: 2.25rem;
    }
}

</style>